/* -------------------------------------- */
/*  АНИМАЦИИ                              */
/* -------------------------------------- */
@keyframes fade-out {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes hero-content {
  0% {
    transform: translate(-50%, -35%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

@keyframes project-hero-fadein {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes placeholder-main-fade-in {
  0%   { transform: translateY(100%); }
  100% { transform: translateY(0%); }
}

/* -------------------------------------- */
/*  БАЗОВЫЕ СТИЛИ HERO                    */
/* -------------------------------------- */
.hero {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 600px) {
  .hero {
    height: 100dvh;
  }
}

.hero__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* -------------------------------------- */
/*  BLURHASH WRAPPER                      */
/* -------------------------------------- */
.blurhash-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
}

.blurhash-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  will-change: transform, opacity;
  transform: translateZ(0);
}

/* При ширине >= 600px: анимация placeholder
   начинается через 1.7s и длится 1.5s */
@media (min-width: 600px) {
  .mainPage .blurhash-wrapper {
    transform: translateY(100%);
    animation: placeholder-main-fade-in 1.5s ease-out 1.9s forwards;
  }
}

/* projectPage: fade-in */
.projectPage .blurhash-wrapper {
  opacity: 0;
  animation: fade-in 1s ease-out 1s forwards;
}

@media (max-width: 600px) {
  .projectPage .blurhash-wrapper {
    opacity: 0;
    animation: fade-in 1s ease-out forwards;
  }
}

/* -------------------------------------- */
/*  ОСНОВНОЕ ФОНОВОЕ ИЗОБРАЖЕНИЕ         */
/* -------------------------------------- */
.hero__bgImg {
  opacity: 1;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;

  /* Оптимизации для анимации opacity */
  will-change: opacity;
  transform: translateZ(0);

  transition: opacity 1s ease-in-out;
}

@media (max-width: 600px) {
  .hero__bgImg {
    transition: opacity 1.5s ease-in-out;
  }
}

.hero__bgImg.hidden {
  opacity: 0;
}

/* -------------------------------------- */
/*  ОВЕРЛЕЙ                               */
/* -------------------------------------- */
.hero__overlay {
  position: absolute;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(24, 24, 24, 0.7) 15.94%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  height: 100%;

  /* Оптимизация для fade-in */
  will-change: opacity;
  transform: translateZ(0);
}

/* -------------------------------------- */
/*  ГЛАВНАЯ СТРАНИЦА (mainPage)           */
/* -------------------------------------- */
@media (max-width: 600px) {
  .mainPage .blurhash-wrapper {
    opacity: 0;
    animation: project-hero-fadein 0.1s ease-out 1s forwards;
  }

  /* Скрываем прелоудер на мобильных (если нужно) */
  .mainPage .hero .preloader {
    display: none;
  }
}

/* Заголовок: hero-content */
.mainPage .pageTitle__content {
  opacity: 0;
  animation: hero-content 1.5s ease-out 3.5s forwards;

  will-change: transform, opacity;
  transform: translateZ(0);
}

/* Оверлей: fade-in */
.mainPage .hero__overlay {
  opacity: 0;
  animation: fade-in 1.5s ease-out 3s forwards;
}

/* -------------------------------------- */
/*  АДАПТИВ (mainPage) НА МОБИЛЬНЫХ       */
/* -------------------------------------- */
@media (max-width: 600px) {
  .mainPage .hero__bgImg {
    opacity: 0;
    animation: project-hero-fadein 1s ease-out 1.5s forwards;
  }

  .mainPage .pageTitle__content {
    opacity: 0;
    animation: hero-content 0.6s ease-out 2.5s forwards;
  }

  .mainPage .hero__overlay {
    opacity: 0;
    animation: fade-in 1s ease-out 2.5s forwards;
  }
}
